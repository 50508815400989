
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      value: {},
      typeError: "",
      fileName: this.$t("select_file"),
    };
  },
  props: {
    model: {
      required: true,
    },
    modelName: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
    },
    textSize: {
      type: String,
      default: "sm",
    },
    label: {
      required: true,
      type: String,
    },
    description: {
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    hasError: {
      required: false,
      type: Boolean,
    },
    error: {
      required: false,
    },
  },
  methods: {
    updateModel(e: any) {
      const selectedImage = e.target.files[0];
      const whiteList = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/heic",
        "image/heif",
        "image",
        "video/mp4",
        "application/pdf",
        "application/zip",
        "application/vnd.rar",
        "application/x-rar-compressed",
        "application/octet-stream",
        "",
      ];

      if (whiteList.includes(selectedImage.type) === true) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedImage);
        reader.onload = () => {
          this.fileName = selectedImage.name;
          const value = {
            name: selectedImage.name,
            modelName: this.modelName,
            size: selectedImage.size,
            type: selectedImage.type,
            lastModified: selectedImage.lastModified,
            base64: reader.result,
          };
          const val = e.target.value;
          this.$emit("update:files", value);
          this.$emit("update:model", val);
          this.$emit("valueChange", val);
        };
        this.typeError = false;
      } else {
        this.typeError = true;
      }
    },
    resetImage() {
      this.$emit("update:files", { modelName: this.modelName });
      this.typeError = false;
      this.fileName = this.$t("select_file");
    },
    open() {
      this.resetImage();
      this.$refs.fileUpload.click();
    },
  },
})
export default class FormUpload extends Vue {}
