
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import { Options, Vue } from "vue-class-component";
import FormRadioGroup from "@/shared/components/FormRadioGroup.vue";
import FormInput from "@/shared/components/FormInput.vue";
import FormUpload from "@/shared/components/FormUpload.vue";
import FormSelect from "@/shared/components/FormSelect.vue";
import { $t } from "@/utils/helpers";

@Options({
  data() {
    return {
      isChildOwnerStatus: false,
      selectedOption: 4,
      selectedTypeOfSending: 1,
      intermediary_id: 0,
      intermediary_person_id: 0,
      frequencyOptions: [
        { id: "yearly", value: 1, label: $t("yearly") },
        { id: "semiannually", value: 2, label: $t("semiannually") },
        { id: "quarterly", value: 4, label: $t("quarterly") },
        { id: "dont_send", value: 0, label: $t("dont_send") }
      ],
      sendingTypeOptions: [
        { id: "email", value: 1, label: $t("email") },
        { id: "clientzone", value: 2, label: $t("clientzone") }
      ],
      frequencyOptionss: ["Mnau 1", " Mnau 2", "mnau 3", "mnau 4"],
      fileName: this.$t("select_file"),
      fileName2: this.$t("select_file"),
      fileName3: this.$t("select_file"),
      fileName4: this.$t("select_file"),
      fileName5: this.$t("select_file"),
      fileName6: this.$t("select_file"),
      file1: {},
      file2: {},
      file3: {},
      file4: {},
      file5: {},
      file6: {},
      v$: useVuelidate(),
      formSubmitted: false,
      form: {
        doc1_front: [],
        doc1_back: "",
        politically_exposed_person: "",
        longText: ""
      },
      files: {}
    };
  },
  components: {
    FormSelect,
    FormUpload,
    FormRadioGroup,
    FormInput
  },
  watch: {
    // selectedOption: {
    //   handler() {
    //     console.log("Selected Frequencies: ", this.selectedOption);
    //   },
    //   deep: true
    // },
  },
  computed: {
    isLoading() {
      return this.$store.getters["onboarding/isLoading"];
    },
    // isChildOwner() {
    //   const profile = this.$store.getters["onboarding/myProfile"];
    //   const child = localStorage.getItem("hasChild");
    //   console.log("CHILD", child);
    //   if (profile.client_owner !== null || profile.client_owner !== "null" && (child !== "false" && child !== null && child !== "null")) {
    //     console.log("Mas clientOwnera", profile?.client_owner);
    //     console.log("Mas decko localStorage", child);
    //     return true;
    //   } else {
    //     console.log("Nemas decko", profile?.client_owner);
    //     return false;
    //   }
    // },
    isChildOwner() {
      return this.isChildOwnerStatus;
    },
    investmentOptions() {
      return [
        { value: true, name: this.$t("yes") },
        { value: false, name: this.$t("no") }
      ];
    },
    isAmlRequired() {
      return this.intermediary_id === 1 && this.intermediary_person_id !== null && this.intermediary_person_id !== 0;
    }
  },
  validations() {
    const validations = {
      form: {
        marketing_agree: { required },
        politically_exposed_person: { required },
        longText: {}
      }
    };

    if (this.isAmlRequired) {
      validations.form.longText = { required };
    }

    return validations;
  },

  mounted() {
    this.$store.dispatch("onboarding/getPep");
    this.checkChildOwnerStatus();
    this.checkIntermediaryId();
  },
  methods: {
    checkIntermediaryId() {
      const profile = this.$store.getters["onboarding/myProfile"];
      // console.log("Profile", profile);
      console.log("Profile", profile);
      this.intermediary_id = profile.intermediary_id;
      this.intermediary_person_id = profile.intermediary_person_id;
      console.log("Intermediary ID", this.intermediary_id);
      console.log("Intermediary Person ID", this.intermediary_person_id);
    },
    checkChildOwnerStatus() {
      const profile = this.$store.getters["onboarding/myProfile"];
      const child = localStorage.getItem("hasChild");
      console.log("CHILD", child);
      if (profile.client_owner !== null && profile.client_owner !== "null" && child !== "false" && child !== null && child !== "null") {
        console.log("Mas clientOwnera", profile?.client_owner);
        console.log("Mas decko localStorage", child);
        this.isChildOwnerStatus = true;
      } else {
        console.log("Nemas decko", profile?.client_owner);
        this.isChildOwnerStatus = false;
      }
    },
    getDescriptionWithIcon(description: any) {
      return `${description} `;
    },
    updateFiles6(e: any) {
      const selectedImage = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onload = () => {
        this.fileName6 = selectedImage.name;
        this.file6 = this.$refs.document6.files[0];
      };
    },
    updateModel(value: string, model: string, fileBase64: any) {
      this.v$.form[model].$touch();
      this.form[model] = value;
      this.files[model] = fileBase64;
    },
    resetImage6() {
      this.fileName6 = this.$t("select_file");
    },
    open6() {
      this.resetImage6();
      this.$refs.document6.click();
    },
    submitForm() {
      this.v$.$validate();
      this.formSubmitted = true;
      const form = this.form;
      const formData = new FormData();
      formData.append("birth_certificate", this.file6);
      formData.append(
        "politically_exposed_person",
        form.politically_exposed_person[0].answer
      );
      formData.append("marketing_agree", form.marketing_agree[0].answer);
      formData.append("statement_frequency", this.selectedOption);
      formData.append("statement_delivery", this.selectedTypeOfSending);
      // formData.append("long_text", form.longText);
      if (this.intermediary_id === 1 && this.intermediary_person_id !== null && this.intermediary_person_id !== 0) {
        formData.append("additional_info", form.longText);
      }

      // console.log("Files1", this.file1);
      // console.log("Files1", this.file2);
      // console.log("Files1", this.file3);
      // console.log("Files1", this.file4);
      // console.log("Files1", this.file5);
      // console.log("Files1", this.file6);

      if (!this.v$.$invalid) {
        this.$store.dispatch("onboarding/sendDocumentsUpload", formData);
      }
    }
  }
})
export default class DocumentsUploadForm extends Vue {
}
