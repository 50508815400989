import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full text-secondary" }
const _hoisted_2 = ["value", "type", "placeholder"]
const _hoisted_3 = { class: "block w-full text-sm mb-2 border-b-2 border-secondary" }
const _hoisted_4 = { class: "block w-full text-sm mb-2 font-serif" }
const _hoisted_5 = {
  key: 0,
  class: "text-danger text-xs absolute mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`text-primary-300 relative text-${_ctx.textSize}`)
  }, [
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
      class: "cursor-pointer"
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
      _createElementVNode("input", {
        value: _ctx.model,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateModel($event))),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetImage && _ctx.resetImage(...args))),
        type: _ctx.type,
        accept: "image/*, image/heic, image/heif, video/mp4, application/pdf, application/zip, application/vnd.rar, application/x-rar-compressed, application/octet-stream, .rar",
        placeholder: _ctx.placeholder || '',
        ref: "fileUpload",
        class: "\n          w-full\n          block\n          hidden\n          bg-transparent\n          focus:outline-none\n          border-b-2 border-secondary\n        "
      }, null, 40, _hoisted_2),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.fileName), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1),
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.error.$message), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}